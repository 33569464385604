import { connect } from 'react-redux';
import Project from '../pages/project';
import * as Actions from '../../states/ducks/project/actions';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    ...state.project,
    loginUserInfo: state.projects.user,
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    init: (projectHash:string, userId:number) => {
      dispatch(Actions.get_project({projectHash, userId}));
      dispatch(Actions.get_project_info({projectHash, userId}));
    },
    reset: () => dispatch(Actions.reset()),
    getScrollPos: (projectHash:string, userId:number):number =>{
      const scrollPos = JSON.parse(window.localStorage.getItem('scrollPos'));
      if(scrollPos && scrollPos.find(pos=>pos.projectHash === projectHash && pos.userId === userId)){
        return scrollPos.find(pos=>pos.projectHash === projectHash && pos.userId === userId).posY;
      }else{
        return 0;
      }
    },
    setScrollPos: (projectHash:string,userId:number,posY:number)=>{
      let scrollPos = JSON.parse(window.localStorage.getItem('scrollPos'));
      if(scrollPos && scrollPos.find(pos=>pos.projectHash === projectHash && pos.userId === userId)){
        scrollPos = scrollPos.map(sPos=>{
          if(sPos.projectHash === projectHash && sPos.userId === userId){
            return {
              projectHash,
              userId,
              posY
            }
          }
          return sPos
        })
      }else if(scrollPos){
        scrollPos.push({
          projectHash,
          userId,
          posY
        })
      }else{
        scrollPos = [];
        scrollPos.push({
          projectHash,
          userId,
          posY
        })
      }
      window.localStorage.setItem('scrollPos', JSON.stringify(scrollPos));
    }
  };
};

const ProjectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Project);

export default ProjectContainer;
