import { animateScroll as scroll } from 'react-scroll';
import * as Types from './types';

const groupPosts = posts => {
  const ary = [];

  //queue送信でresが0件のとき
  if (typeof posts === 'undefined') return [];

  //発話のとき
  if (posts.speakerId === 0)
    return [
      {
        type: posts[0].isQueued ? 'queue' : 'me',
        postId: posts[0].postId,
        sourceId: posts[0].sourceId,
        referrerUser: posts[0].referrerUser,
        posts: posts
      }
    ];

  //複数のresをグルーピングする
  posts.reduce(
    (preview, current, index, array) => {
      if (preview.postId !== current.postId) {
        if (current.speakerId == 0) {
          ary.push({
            type: 'you',
            postId: current.postId,
            posts: []
          });
        } else {
          ary.push({
            type: current.isQueued ? 'queue' : 'me',
            postId: current.postId,
            sourceId: current.sourceId,
            referrerUser: current.referrerUser,
            posts: []
          });
        }
      }

      ary[ary.length - 1].posts.push(current);
      return current;
    },
    { postId: 0 }
  );

  return ary;
};

const mergePosts = (groupedPosts, othersPosts) => {
  if (othersPosts.length === 0) return groupedPosts;

  const transformedPosts = groupedPosts.map(group => {
    const filtered = othersPosts
      .filter(post => post.postId === group.postId && !post.isDeleted)
      .sort((a, b) => a.presentedId - b.presentedId);
    if (filtered.length > 0) {
      return {
        ...group,
        posts: [...group.posts, ...filtered.map(f => ({ ...f, word: f.contents }))]
      };
    }
    return group;
  });

  return transformedPosts;
};

const project = (state = {}, action) => {
  switch (action.type) {
    //PROJECT取得成功
    case Types.GET_PROJECT_INFO:
      return {
        ...state,
        projectSummary: action.payload
      };

    //PROJECT取得成功
    case Types.get_project.fulfilled.toString():
      let createdUserId, description, loginUserId, postedUserIds, projectId, usersInfo;
      ({
        createdUserId,
        description,
        loginUserId,
        postedUserIds,
        projectId,
        usersInfo
      } = action.payload.data);

      const timelines = action.payload.timelines.map(tl => {
        let queue = [];
        tl.myPosts.reduce(
          (preview, current, index, array) => {
            if (!preview.isQueued && current.isQueued) {
              queue = [current.word];
            } else if (preview.isQueued && current.isQueued) {
              queue.push(current.word);
            }

            if (preview.isQueued && !current.isQueued) {
              queue = [];
            }

            return current;
          },
          { isQueued: false }
        );

        const posterUserIds = tl.postedUserIds;
        const index = posterUserIds.indexOf(tl.userId);
        if (index >= 0) {
          posterUserIds.splice(index, 1);
          posterUserIds.unshift(tl.userId);
        }

        return {
          userId: tl.userId,
          posts: mergePosts(groupPosts(tl.myPosts), tl.othersPosts),
          queue: queue,
          shared: tl.userId !== loginUserId,
          postedUserIds: postedUserIds,
          usersInfo: tl.usersInfo
        };
      });

      return {
        ...state,
        ...{ createdUserId, description, loginUserId, postedUserIds, projectId, usersInfo },
        timelines: timelines
      };

    case Types.get_project.rejected.toString():
      if (action.payload.response.status === 401) {
        alert(action.payload.response.data.msg);
        window.location.href = '/login';
      }
      return state;

    //WORDのPOST成功
    case Types.get_status.fulfilled.toString():
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (tl.userId === action.payload.userId) {
            return {
              ...tl,
              queue: action.payload.isQueued ? tl.queue : []
            };
          } else {
            return tl;
          }
        })
      };
    //WORDのPOST失敗
    case Types.get_status.rejected.toString():
      if (action.payload.response.status === 401) {
        alert(action.payload.response.data.msg);
        window.location.href = '/login';
        return state;
      } else {
        alert('no result');
        return {
          ...state,
          timelines: state.timelines.map(tl => ({
            ...tl,
            queue: [],
            thinking: false
          }))
        };
      }
    case Types.shared_project_status.fulfilled.toString():
      return {
        ...state,
        joined: true
      };
    case Types.shared_project_status.rejected.toString():
      window.location.pathname = '/';
      return state;

    case Types.QUEUE:
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (parseInt(tl.userId) === parseInt(action.payload.userId)) {
            return {
              ...tl,
              queue: [...tl.queue, action.payload.text]
            };
          } else {
            return tl;
          }
        })
      };
    case Types.REMOVE_QUEUE:
      return {
        ...state,
        timelines: state.timelines.map(tl => ({
          ...tl,
          queue: []
        }))
      };
    case Types.TO_BOTTOM:
      const tls = state.timelines.filter(
        tl => parseInt(tl.userId) === parseInt(action.payload.userId)
      );
      if (tls.length === 0) return state;

      scroll.scrollToBottom({
        duration: action.payload.duration,
        containerId: tls[0].tlId,
        smooth: 'easeOutQuad'
      });
      return state;
    case Types.RESTART:
      return {
        ...state,
        messages: []
      };
    case Types.ERROR:
      return {
        ...state,
        timelines: [
          {
            ...state.timelines[0],
            error: action.payload
          }
        ]
      };
    case Types.PUT_TEXT:
      if (!action.payload) return state;

      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (parseInt(tl.userId) === parseInt(action.payload.userId)) {
            return {
              ...tl,
              posts: [...tl.posts, ...groupPosts(action.payload.data)]
            };
          } else {
            return tl;
          }
        })
      };
    case Types.START_TO_SAY:
      return {
        ...state,
        timelines: state.timelines.map(tl => ({
          ...tl,
          thinking: true
        }))
      };
    case Types.READY_TO_SAY:
      return {
        ...state,
        timelines: state.timelines.map(tl => ({
          ...tl,
          thinking: false
        }))
      };
    case Types.RESET:
      return {
        ...state,
        projectSummary: null,
        timelines: []
      };
    case Types.toggle_pin_status.fulfilled.toString():
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (tl.userId === action.payload.userId) {
            return {
              ...tl,
              posts: tl.posts.map(group => {
                return {
                  ...group,
                  posts: group.posts.map(post => {
                    if (
                      post.postId === action.payload.postId &&
                      post.presentedId === action.payload.presentedId
                    ) {
                      return {
                        ...post,
                        isPin: !post.isPin
                      };
                    } else {
                      return post;
                    }
                  })
                };
              })
            };
          } else {
            return tl;
          }
        })
      };
    case Types.note_status.fulfilled.toString():
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (tl.userId === action.payload.userId) {
            return {
              ...tl,
              note: false,
              posts: tl.posts.map(group => {
                return {
                  ...group,
                  posts: group.posts.map(post => {
                    if (
                      post.postId === action.payload.postId &&
                      post.presentedId === action.payload.presentedId
                    ) {
                      return {
                        ...post,
                        memo: action.payload.memo
                      };
                    } else {
                      return post;
                    }
                  })
                };
              })
            };
          } else {
            return tl;
          }
        })
      };
    case Types.selected_status.fulfilled.toString():
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (tl.userId === action.payload.userId) {
            return {
              ...tl,
              posts: tl.posts.map(group => {
                return {
                  ...group,
                  posts: group.posts.map(post => {
                    if (
                      post.postId === action.payload.postId &&
                      post.presentedId === action.payload.presentedId
                    ) {
                      return {
                        ...post,
                        is_selected: true
                      };
                    } else {
                      return post;
                    }
                  })
                };
              })
            };
          } else {
            return tl;
          }
        })
      };
    case Types.post_others_status.fulfilled.toString():
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (tl.userId === action.payload.data.tlUserId) {
            const posts = tl.posts.filter(group => group.postId === action.payload.data.postId)[0]
              .posts;
            const presentedId = [...posts].reverse()[0].presentedId + 1;

            return {
              ...tl,
              posts: mergePosts(tl.posts, [
                {
                  contents: action.payload.data.memo,
                  isDeleted: false,
                  postId: action.payload.data.postId,
                  presentedId,
                  postTime: Date.now() / 1000,
                  speakerId: action.payload.user.id
                }
              ])
            };
          } else {
            return tl;
          }
        })
      };
    case Types.put_others_status.fulfilled.toString():
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (tl.userId === action.payload.data.tlUserId) {
            return {
              ...tl,
              posts: tl.posts.map(group => {
                return {
                  ...group,
                  posts: group.posts
                    .filter(post => {
                      return (
                        //isdeleted=1で該当するポスト[じゃない]ものは true filterしない
                        !(
                          action.payload.data.isDeleted === '1' &&
                          post.postId === action.payload.data.postId &&
                          post.presentedId === action.payload.data.presentedId &&
                          parseInt(post.speakerId) === action.payload.data.speakerId
                        )
                      );
                    })
                    .map(post => {
                      let postData = post;
                      if (
                        post.postId === action.payload.data.postId &&
                        post.presentedId === action.payload.data.presentedId &&
                        post.speakerId === action.payload.data.speakerId
                      ) {
                        postData.word = action.payload.data.memo;
                      }
                      return postData;
                    })
                };
              })
            };
          } else {
            return tl;
          }
        })
      };
    case Types.TOGGLE_PART_OF_SPEECH:
      const mappedPoS = state.partOfSpeech.map(PoS => {
        if (action.payload === PoS.name) {
          return {
            ...PoS,
            valid: !PoS.valid
          };
        }
        return PoS;
      });

      const filteredPoS = mappedPoS.filter(PoS => PoS.valid);

      if (filteredPoS.length < 1) return state;

      window.localStorage.setItem('partOfSpeech', JSON.stringify(mappedPoS));
      return {
        ...state,
        partOfSpeech: mappedPoS
      };

    case Types.TOGGLE_SOURCE:
      const mapped = state.sources.map(source => {
        if (action.payload === source.name) {
          return {
            ...source,
            valid: !source.valid
          };
        }
        return source;
      });

      const filtered = mapped.filter(source => source.valid);

      if (filtered.length < 1) return state;

      window.localStorage.setItem('sources', JSON.stringify(mapped));
      return {
        ...state,
        sources: mapped
      };
    case Types.TOGGLE_NOTE:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          timelines: state.timelines.map(tl => ({
            ...tl,
            note: action.payload
          }))
        };
      } else {
        return {
          ...state,
          timelines: state.timelines.map(tl => ({
            ...tl,
            note: !tl.note
          }))
        };
      }
    case Types.ENABLE_TOOLTIP:
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (parseInt(tl.userId) === parseInt(action.payload.userId)) {
            return {
              ...tl,
              toolTip: true,
              selectedPostId: action.payload.postId,
              selectedPresentedId: action.payload.presentedId
            };
          } else {
            return tl;
          }
        })
      };
    case Types.DISABLE_TOOLTIP:
      return {
        ...state,
        timelines: state.timelines.map(tl => ({
          ...tl,
          toolTip: false,
          note: false,
          selectedPostId: null,
          selectedPresentedId: null
        }))
      };
    case Types.SET_TL_REF:
      let tl = [];
      if (state.timelines.length === 0) {
        tl = [{ ...action.payload }];
      } else if (state.timelines.filter(tl => tl.userId === action.payload.userId).length > 0) {
        tl = state.timelines.map(tl => {
          if (parseInt(tl.userId) === action.payload.userId) {
            return {
              ...tl,
              tlId: action.payload.tlId,
              tlRef: action.payload.tlRef
            };
          } else {
            return tl;
          }
        });
      } else {
        tl = [...state.timelines, { ...action.payload }];
      }

      return {
        ...state,
        timelines: tl
      };
    case Types.post_related_word_status.fulfilled.toString():
      let words;
      if (action.payload.type === 'dictionary') {
        words = action.payload.data.returnWords;
      } else {
        words = [
          ...state.relatedWords.words,
          ...(action.payload.data.returnWords || action.payload.returnPhrases || [])
        ];
      }
      return {
        ...state,
        relatedWords: {
          title: action.payload.data.searchWord,
          type: action.payload.type,
          words: words
        }
      };
    case Types.RESET_RELATED_WORD:
      return {
        ...state,
        relatedWords: {
          title: '',
          type: 'dictionary',
          words: []
        }
      };
    case Types.SET_COPIED_USER:
      return {
        ...state,
        timelines: state.timelines.map(tl => {
          if (parseInt(tl.userId) === parseInt(action.payload.originalUserId)) {
            return {
              ...tl,
              posts: tl.posts.map(group => {
                return {
                  ...group,
                  posts: group.posts.map(post => {
                    if (
                      post.postId === action.payload.originalPostId &&
                      post.presentedId === action.payload.originalPresentedId &&
                      post.speakerId === '0'
                    ) {
                      return {
                        ...post,
                        copiedUserIds: [
                          ...post.copiedUserIds,
                          {
                            postId: action.payload.copiedPostId,
                            userId: action.payload.copiedUserId
                          }
                        ]
                      };
                    } else {
                      return post;
                    }
                  })
                };
              })
            };
          } else {
            return tl;
          }
        })
      };
    default:
      return state;
  }
};

export default project;
