import React, { Component } from 'react';
import { Pin } from '../common/icons';
import {setup} from 'bem-cn';

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('chat-bubble')

interface Props  {
  type: 'me'|'you'|'queue',
  floated: boolean,
  noteAppear:boolean,
  word:string|string[],
  note():void,
  togglePin():void,
  toolTipBottom: number,
  toggleRelated(type:string):void
}

interface State {
}

class Chat extends Component<Props, State> {
  public state: State = {
  }

  constructor(props:Props){
    super(props);
    this.togglePin = this.togglePin.bind(this)
    this.note = this.note.bind(this)
    this.wikipedia = this.wikipedia.bind(this)
    this.dictionary = this.dictionary.bind(this)
    this.combine = this.combine.bind(this)
    this.phrase = this.phrase.bind(this)
  }

  componentDidMount(){
  }

  togglePin(e){
    e.stopPropagation();
    this.props.togglePin();
  }

  note(e){
    e.stopPropagation();
    this.props.note();
  }

  wikipedia(e){
    e.stopPropagation();
    window.open(`https://ja.wikipedia.org/wiki/${this.props.word}`);
  }

  dictionary(e){
    e.stopPropagation();
    this.props.toggleRelated('dictionary');
  }

  combine(e){
    e.stopPropagation();
    this.props.toggleRelated('combine');
  }

  phrase(e){
    e.stopPropagation();
    this.props.toggleRelated('phrase');
  }

  shouldComponentUpdate(nextProps:Props, nextState:State){
    return true
  }

  render() {
    return (
      <div className={b("tooltip", {"show":this.props.floated, "note":this.props.noteAppear})}>
        {this.props.type !== 'me' && <div className={b("tooltip-item")} onClick={this.togglePin}>
          <Pin/>
          <span>ピン</span>
        </div>}

        <div className={b("tooltip-item")} onClick={this.note}>
          <img src="/images/note.svg" alt=""/>
          <span>メモ</span>
        </div>

        {this.props.type !== 'me' && <div className={b("tooltip-item")} onClick={this.wikipedia}>
          <img src="/images/help.svg" alt=""/>
          <span>出典</span>
        </div>}

        {this.props.type !== 'me' && <div className={b("tooltip-item")} onClick={this.dictionary}>
          <img src="/images/dictionary.svg" alt=""/>
          <span>辞書</span>
        </div>}

        {this.props.type !== 'me' && <div className={b("tooltip-item")} onClick={this.combine}>
          <img src="/images/combine.svg" alt=""/>
          <span>組合せ</span>
        </div>}

        {this.props.type !== 'me' && <div className={b("tooltip-item")} onClick={this.phrase}>
          <img src="/images/phrase.svg" alt=""/>
          <span className="phrase">フレーズ</span>
        </div>}
      </div>
    );
  }
}

export default Chat;
