import { connect } from 'react-redux';
import * as Actions from '../../states/ducks/project/actions';

import TimeLine from '../pages/timeline';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  if(state.project.timelines.length>0){
    return {
      ...ownProps,
      loginUserInfo: state.projects.user,
      projectId: state.project.projectId,
      sources: state.project.sources,
      partOfSpeech: state.project.partOfSpeech,
      relatedWords: state.project.relatedWords,
    };
  } else {
    return {
      ...ownProps,
      posts: [],
      queue: [],
      projectId: state.project.projectId,
    }
  }
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    //get: (projectHash:string, userId:number) => dispatch(Actions.get_timeline({projectHash, userId})),
    send: (userId, projectId, postId, presentedId, text, sourceId) =>
      dispatch(Actions.send({ userId, projectId, postId, presentedId, text, isQueued: false, sourceId})),
    sendQuietly: (userId, projectId, postId, text) =>
      dispatch(Actions.sendQuietly({ userId, projectId, postId, text })),
    sendOnlyQueue: (userId, projectId) => dispatch(Actions.sendOnlyQueue({userId, projectId})),
    toggleSource: name => dispatch(Actions.toggle_source(name)),
    togglePartOfSpeech: name => dispatch(Actions.toggle_part_of_speech(name)),
    sendNote: (userId, projectId, postId, presentedId, memo) =>
      dispatch(Actions.note({ userId, projectId, postId, presentedId, memo })),
    toggleNote: (toggle?: boolean) => dispatch(Actions.toggle_note(toggle)),
    disableToolTip: ()=> dispatch(Actions.disable_tooltip()),
    setTlRef: (userId, tlId, tlRef)=> dispatch(Actions.setTlRef({userId,tlId, tlRef})),
    sendTlMemo: (postId:number,tlUserId:number,projectId:number,memo:string)=> dispatch(Actions.send_tl_memo({postId,tlUserId,projectId,memo})),
    updateTlMemo: (postId:number,presentedId:number,tlUserId:number,projectId:number,speakerId:number,memo:string)=> dispatch(Actions.update_tl_memo({postId,presentedId,tlUserId,projectId,speakerId,memo})),
    removeTlMemo: (postId:number,presentedId:number,tlUserId:number,projectId:number,speakerId:number)=> dispatch(Actions.update_tl_memo({postId,presentedId,tlUserId,projectId,speakerId,isDeleted: '1'})),
    postRelatedWord: (type:string, word:string)=>dispatch(Actions.post_related_word({type, word})),
    resetRelatedWord: ()=>dispatch(Actions.reset_related_word())
  };
};

const TimeLineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeLine);

export default TimeLineContainer;
