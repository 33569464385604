const Config = {
  NAME: '',
  DOMAIN: 'https://cypar-tane.com',
  API: {
    BASE: '/api',
    GET_ACCOUNT: { url: '/account', method: 'get' },
    PUT_ACCOUNT: { url: '/account', method: 'put' },
    GET_PROJECTS: { url: '/projects', method: 'get' },
    GET_PROJECT: { url: '/project/{projectHash}/{userId}', method: 'get' },
    POST_PROJECT: { url: '/project', method: 'post' },
    PUT_PROJECT: { url: '/project', method: 'put' },
    PUT_SHARED_PROJECT: { url: '/sharedProject', method: 'put' },
    POST_LOGIN: { url: '/login', method: 'post' },
    POST_WORDS: { url: '/words', method: 'post' },
    PUT_POST: { url: '/post', method: 'put' },
    POST_SHARED_PROJECT: { url: '/sharedProject', method: 'post' },
    POST_OTHERS_MEMO: { url: '/othersMemo', method: 'post' },
    PUT_OTHERS_MEMO: { url: '/othersMemo', method: 'put' },
    POST_DICTIONARY: { url: '/dictionary', method: 'post' },
    POST_PAIRWORD: { url: '/pairWord', method: 'post' },
    POST_PHRASE: { url: '/phrase', method: 'post' },
    POST_COPY_WORD: { url: '/copyWord', method: 'post' }
  },
  MSG: {},
  FPS: 30,
  SUPPORT: {
    IE_MIN: 11.0,
    IOS_MIN: 9.0,
    ANDROID_MIN: 4.2
  },
  MAX_UPLOAD_SIZE: 5000000 //5MB
};

export default Config;
