import createAsyncActions from '../../../utils/createAsyncActions';

const PREFIX = 'PROJECT';
const SEPARATOR = '_';

//sync actions
const REMOVE_QUEUE = PREFIX + SEPARATOR + 'REMOVE_QUEUE';
const QUEUE = PREFIX + SEPARATOR + 'QUEUE';
const GET_STATUS = PREFIX + SEPARATOR + 'GET_STATUS'; //自分の発言をサーバーに送る、スクロールする、相手の発言待機状態、相手の発言表示、スクロールする
const GET_ALL = PREFIX + SEPARATOR + 'GET_ALL'; //自分の発言をサーバーに送る、スクロールする、相手の発言待機状態、相手の発言表示、スクロールする
const PUT_TEXT = PREFIX + SEPARATOR + 'PUT_TEXT';
const TO_BOTTOM = PREFIX + SEPARATOR + 'TO_BOTTOM';
const READY_TO_SAY = PREFIX + SEPARATOR + 'READY_TO_SAY';
const START_TO_SAY = PREFIX + SEPARATOR + 'START_TO_SAY';
const RESTART = PREFIX + SEPARATOR + 'RESTART';
const RESET = PREFIX + SEPARATOR + 'RESET';
const ERROR = PREFIX + SEPARATOR + 'ERROR';
const GET_PROJECT = PREFIX + SEPARATOR + 'GET_PROJECT';
const RELOAD_PROJECT = PREFIX + SEPARATOR + 'RELOAD_PROJECT';
const GET_TIMELINE = PREFIX + SEPARATOR + 'GET_TIMELINE';
const GET_PROJECT_INFO = PREFIX + SEPARATOR + 'GET_PROJECT_INFO';
const TOGGLE_PIN = PREFIX + SEPARATOR + 'TOGGLE_PIN';
const TOGGLE_SOURCE = PREFIX + SEPARATOR + 'TOGGLE_SOURCE';
const TOGGLE_PART_OF_SPEECH = PREFIX + SEPARATOR + 'TOGGLE_PART_OF_SPEECH';
const NOTE = PREFIX + SEPARATOR + 'NOTE';
const SELECTED = PREFIX + SEPARATOR + 'SELECTED';
const SHARED_PROJECT = PREFIX + SEPARATOR + 'SHARED_PROJECT';
const TOGGLE_NOTE = PREFIX + SEPARATOR + 'TOGGLE_NOTE';
const ENABLE_TOOLTIP = PREFIX + SEPARATOR + 'ENABLE_TOOLTIP';
const DISABLE_TOOLTIP = PREFIX + SEPARATOR + 'DISABLE_TOOLTIP';
const SET_TL_REF = PREFIX + SEPARATOR + 'SET_TL_REF';
const POST_OTHERS_MEMO = PREFIX + SEPARATOR + 'POST_OTHERS_MEMO';
const PUT_OTHERS_MEMO = PREFIX + SEPARATOR + 'PUT_OTHERS_MEMO';
const POST_RELATED_WORD = PREFIX + SEPARATOR + 'POST_RELATED_WORD';
const RESET_RELATED_WORD = PREFIX + SEPARATOR + 'RESET_RELATED_WORD';
const POST_COPY_WORD = PREFIX + SEPARATOR + 'POST_COPY_WORD';
const SET_COPIED_USER = PREFIX + SEPARATOR + 'SET_COPIED_USER';

//async actions
const get_project = createAsyncActions(GET_PROJECT);
const reload_project_status = createAsyncActions(RELOAD_PROJECT);
const get_timeline = createAsyncActions(GET_TIMELINE);
const get_status = createAsyncActions(GET_STATUS);
const get_all_status = createAsyncActions(GET_ALL);
const toggle_pin_status = createAsyncActions(TOGGLE_PIN);
const note_status = createAsyncActions(NOTE);
const selected_status = createAsyncActions(SELECTED);
const shared_project_status = createAsyncActions(SHARED_PROJECT);
const post_others_status = createAsyncActions(POST_OTHERS_MEMO);
const put_others_status = createAsyncActions(PUT_OTHERS_MEMO);
const post_related_word_status = createAsyncActions(POST_RELATED_WORD);
const post_copy_word_status = createAsyncActions(POST_COPY_WORD);

export {
  REMOVE_QUEUE,
  QUEUE,
  PUT_TEXT,
  TO_BOTTOM,
  READY_TO_SAY,
  START_TO_SAY,
  RESTART,
  RESET,
  ERROR,
  TOGGLE_SOURCE,
  TOGGLE_PART_OF_SPEECH,
  TOGGLE_NOTE,
  ENABLE_TOOLTIP,
  DISABLE_TOOLTIP,
  SET_TL_REF,
  GET_PROJECT_INFO,
  RESET_RELATED_WORD,
  SET_COPIED_USER,
  get_project,
  reload_project_status,
  get_timeline,
  get_status,
  get_all_status,
  toggle_pin_status,
  note_status,
  selected_status,
  shared_project_status,
  post_others_status,
  put_others_status,
  post_related_word_status,
  post_copy_word_status
};
