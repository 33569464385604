import React, { Component, Fragment } from 'react';
import Hammer from 'react-hammerjs';
import { CSSTransition } from 'react-transition-group';
import escapeHtml from 'escape-html';
import Post from '../../../types/post'
import UserIcon from '../common/user-icon'
import {setup} from 'bem-cn';
import ToolTip from './tooltip'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('chat-bubble')

interface Props extends Post {
  type: 'me'|'you'|'queue',
  shared:boolean,
  floated: boolean,
  note():any,
  togglePin():any,
  enableToolTip?(obj:object):any,
  visibleDate?:any,
  send?(text:string):any,
  text?:any,
  queue?:any,
  sendQueue?(text:string):any,
  source?:any,
  mode?:any,
  noteAppear:boolean,
  corpus?: string,
  toggleRelated(type:string, word:string):void,
  getUserInfo(userId:number):any,
  jump(postId:number, userId:number):void,
}

interface State {
  bubbleHeight:string|number,
  toolTipBottom: number,
  note: boolean
}

class Chat extends Component<Props, State> {
  public state: State = {
    bubbleHeight: 'auto',
    toolTipBottom: 70,
    note: false
  }

  private noteNode:any = null;
  private contentNode:any = null;

  constructor(props:Props){
    super(props);
    this.send = this.send.bind(this)
    this.press = this.press.bind(this)
    this.sendQueue = this.sendQueue.bind(this)
    this.togglePin = this.togglePin.bind(this)
    this.note = this.note.bind(this)
    this.wikipedia = this.wikipedia.bind(this)
  }

  componentDidMount(){
    this.updateHeight();
  }

  press(e){
    this.setState({
      toolTipBottom: -10 - (!!this.props.memo?0:this.contentNode.clientHeight)
    })

    if(e.target.tagName === 'BUTTON')return;
    if(this.props.shared)return;
    e.srcEvent.stopImmediatePropagation();

    this.props.enableToolTip({
      postId:this.props.postId,
      presentedId:this.props.presentedId
    });
  }

  send(e){
    if(typeof this.props.word === 'string')this.props.send(this.props.word);
  }

  sendQueue(e){
    e.srcEvent.stopImmediatePropagation();
    if(typeof this.props.word === 'string'){
      this.props.sendQueue(this.props.word);
    }else{
      this.props.sendQueue(this.props.word[0]);
    }
  }

  togglePin(){
    if(this.props.shared)return;
    this.props.togglePin();
  }

  note(){
    this.setState({
      note: true
    });
    this.props.note();
  }

  wikipedia(e){
    e.stopPropagation();
    window.open(`https://ja.wikipedia.org/wiki/${this.props.word}`);
  }

  updateHeight(){
    let height = !this.noteNode?'auto':this.noteNode.clientHeight;

    this.setState({
      bubbleHeight: height
    })
  }

  componentDidUpdate(){
    this.updateHeight();
  }

  shouldComponentUpdate(nextProps:Props, nextState:State){
    return this.props.isPin !== nextProps.isPin
      || this.props.type !== nextProps.type
      || this.props.isQueued !== nextProps.isQueued
      || this.props.isSelected !== nextProps.isSelected
      || this.props.floated !== nextProps.floated
      || (this.props.copiedUserIds && this.props.copiedUserIds.length !== nextProps.copiedUserIds.length)
      || this.state.bubbleHeight !== nextState.bubbleHeight
  }

  omit(content){
    if(content.length > 9){
      return `${content.slice(0,8)}…`;
    }else{
      return content;
    }
  }

  contents(){
    if(typeof this.props.word === 'string'){
      if(!this.props.floated && this.props.type !== 'me'){
        return [this.omit(this.props.word)];
      } else {
        return this.props.word.split('&')
      }
    }else{
      if(this.props.word.length === 1 && this.props.word[0].indexOf('&') >= 0){
        return this.props.word[0].split('&')
      }else{
        return this.props.word
      }
    }
  }

  nl2br(str:String){
    const escaped = escapeHtml(str).replace(/\n/g,"<br/>")
    return (
      <Fragment>
        <div dangerouslySetInnerHTML={{__html: escaped}}></div>
      </Fragment>
    )
  }

  getWord(){
    if(typeof this.props.word === 'string'){
      return this.props.word
    }else{
      return this.props.word[0]
    }
  }

  render() {
    const options:object = {
      recognizers: {
        press: {
          time: 200
        }
      }
    }
    const height:string = typeof this.state.bubbleHeight==='string'?'auto':`${50+this.state.bubbleHeight}px`;
    const contents:Array<string> = this.contents();
    const {type, floated, noteAppear, word} = this.props

    return (
      <Fragment>
        <div id={`post_${this.props.postId}_presented_${this.props.presentedId}`} className={b({"has-note": !!this.props.memo || (this.props.copiedUserIds && this.props.copiedUserIds.length > 0), "pinned": this.props.isPin, "selected": this.props.isSelected})} style={{height: height}}>
          <div
            ref={node=>{this.contentNode=node}}
            className={b("inner", {"floated": this.props.floated, "pinned": this.props.isPin, "selected": this.props.isSelected})}
          >
            <Hammer
              options={options}
              onTap={this.send}
              onPress={this.press}
            >
              <div>
              <CSSTransition in={true} appear={true} timeout={10}>
              <div className="chat-bubble__content">
                <div className={b("text")} dangerouslySetInnerHTML={{__html: this.contents().join('<span>+</span>')}}/>

                {contents.length < 2 && !this.props.isQueued && !this.props.shared &&
                  <div className={b("buttons")}>
                    <Hammer
                      className="chat-bubble__button-wrapper"
                      onTap={this.sendQueue}
                    >
                      <button/>
                    </Hammer>
                  </div>
                }
              </div>
              </CSSTransition>
              </div>
            </Hammer>
            <span className={b("corpus")}>
              {this.props.corpus}
            </span>
          </div>

          {(!!this.props.memo || (this.props.copiedUserIds && this.props.copiedUserIds.length>0)) &&
            <div className={b("note")} ref={node=>{this.noteNode=node}}>
              {/** コピーしたユーザー一覧 */}
              {this.props.copiedUserIds !== undefined && this.props.copiedUserIds.length>0 && this.props.copiedUserIds.map((info,index)=>{
                const userInfo = this.props.getUserInfo(info.userId);

                return (
                  <div key={index} className={b('copied-user')} onClick={()=>{this.props.jump(info.postId, info.userId)}}><UserIcon image={userInfo.image} size={24} style={{verticalAlign: 'middle'}}/>{userInfo.name}さんが引用しました</div>
                )
              })}

              {/** 吹き出しメモ */}
              {this.nl2br(this.props.memo)}
            </div>
          }

          <ToolTip
            {...{type,floated,noteAppear,word}}
            note={this.note}
            togglePin={this.togglePin}
            toolTipBottom={this.state.toolTipBottom}
            toggleRelated={type=>this.props.toggleRelated(type, this.getWord())}
          />
        </div>
      </Fragment>
    );
  }
}

export default Chat;
